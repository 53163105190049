import * as Mui from '@material-ui/core'
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides'
import {
  ToggleButtonClassKey,
  ToggleButtonGroupClassKey,
} from '@material-ui/lab'
import { CSSProperties } from '@material-ui/styles'
import { useSpMedia } from '@/lib/hooks/useMedia'

/**
 * Material lab componentsを含めたOverrides
 * 型定義ファイルが無いため、必要に応じて追加する
 * https://github.com/mui-org/material-ui/issues/12164#issuecomment-564041219
 *
 * @interface Overrides
 * @extends {CoreOverrides}
 */
export interface Overrides extends CoreOverrides {
  MuiToggleButton?:
    | Partial<
        Record<ToggleButtonClassKey, CSSProperties | (() => CSSProperties)>
      >
    | undefined
  MuiToggleButtonGroup?:
    | Partial<
        Record<ToggleButtonGroupClassKey, CSSProperties | (() => CSSProperties)>
      >
    | undefined
  MuiGridListTileBar?:
    | Partial<
        Record<
          Mui.GridListTileBarClassKey,
          CSSProperties | (() => CSSProperties)
        >
      >
    | undefined
}

// @ts-expect-error MUIの型が合わないようなので様子見
const GLOBAL_THEME_OPTIONS: Omit<Mui.ThemeOptions, 'overrides'> = Object.freeze(
  {
    spacing: 4,
    typography: {
      fontFamily: ['Noto Sans JP', 'sans-serif'].join(','),
    },
    props: {
      MuiPaper: { elevation: 0 }, //Paperにドロップシャドウはつけない
      MuiButton: { disableElevation: true }, //ボタンにドロップシャドウはつけない
      MuiCheckbox: { color: 'primary' }, //チェックボックスの色はprimaryとする
      MuiRadio: { color: 'primary' }, //ラジオボタンの色はprimaryとする
      MuiTabs: { indicatorColor: 'primary', scrollButtons: 'off' },
      MuiTabScrollButton: { disabled: true },
      //MuiTooltip: { open: true }, //ツールチップの確認用
    },
    palette: {
      primary: {
        main: '#2999D9',
        contrastText: '#fff',
      },
      secondary: {
        main: '#dc582a',
      },
      warning: {
        main: '#b99724',
      },
      success: {
        main: '#718b1b',
      },
      background: {
        default: '#f2f5fa',
      },
      text: {
        primary: '#555555',
        secondary: '#8b8b8b',
        error: '#dc582a',
      },
    },
  }
)

//MaterialUIのThemeで、全ての画面で上書きしてしまっても良いようなものをここに記載する。
// @ts-expect-error MUIの型が合わないようなので様子見
const globalOverrides: Overrides = Object.freeze({
  MuiPaper: {
    rounded: {
      //Paperの角はすべて角丸16px
      borderRadius: '16px',
    },
  },

  MuiList: {
    root: {
      padding: '8px',
    },
  },

  //タブボタン
  MuiTabs: {
    root: {
      minHeight: 'inherit',
      margin: '0 0 16px',
    },
    indicator: {
      height: 2,
      borderRadius: 2,
    },
  },
  //タブコンテンツ
  MuiTab: {
    root: {
      padding: 16,
      fontSize: 12,
      minHeight: 'inherit',
      maxWidth: 'inherit',
      minWidth: 'inherit',
      '@media (min-width: 600px)': {
        minWidth: 'inherit',
      },
      '&$selected': {
        fontWeight: 'bold',
      },
    },
  },

  //タイトル
  MuiTypography: {
    //TextWithLabel,
    body1: {
      fontSize: 14,
    },
    //ステッパーのタイトル
    body2: {
      fontSize: 12,
    },
    //DetailBoxタイトル
    h3: {
      fontSize: '14px !important',
      fontWeight: 'bold',
    },
    //キャプション
    subtitle1: {
      color: '#8B8B8B',
      fontSize: 12,
    },
    //boxを突き出る特殊タイトル
    subtitle2: {
      fontWeight: 'bold',
      background: '#fff',
    },
  },

  //区切り線
  MuiDivider: {
    root: {
      //線の色を設定
      backgroundColor: '#e0e0e0',
    },
  },

  //ボタン
  MuiButton: {
    root: {
      //ボタンの角はすべて角丸8px、通常ボタンの高さは32px
      borderRadius: '8px',
      padding: '5px 16px 6px 16px',
      fontSize: '12px',
    },
    sizeSmall: {
      //小ボタンの高さは24px
      padding: '1px 12px 2px 12px',
      fontSize: '12px',
    },
    iconSizeMedium: {
      //ボタン付きのアイコンは12px
      '& > *:first-child': {
        fontSize: '12px',
      },
    },
    iconSizeSmall: {
      //ボタン付きのアイコンは12px
      '& > *:first-child': {
        fontSize: '12px',
        marginTop: '2px',
      },
    },
    startIcon: {
      //ボタン付きのアイコン位置調整
      marginRight: '4px',
      marginTop: '1px',
    },
    text: {
      //テキストボタンのフォントサイズは14px
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '0px 16px 0px 16px',
    },
    label: {
      whiteSpace: 'pre',
    },
  },
  MuiLink: {
    button: {
      //テキストボタンのフォントサイズ統一
      fontSize: '14px',
    },
  },

  //テーブル
  MuiTableCell: {
    root: {
      fontSize: '14px',
      textSizeAdjust: '100%', //iOSの文字サイズ自動調整を回避
    },
    head: {
      //テーブルのheadの余白は狭い
      fontSize: '12px',
      padding: '4px 16px',
      whiteSpace: 'nowrap',
      color: '#8B8B8B',
    },
  },

  //ツールチップ
  MuiChip: {
    root: {
      color: '#030303',
      fontSize: 12,
    },
  },

  //ダイアログ
  MuiDialog: {
    paper: {
      height: '100%',
    },
  },
  MuiDialogContent: {
    dividers: {
      padding: '32px',
    },
  },
  MuiDialogTitle: {
    root: {
      padding: '16px 32px',
    },
  },
  MuiDialogActions: {
    root: {
      padding: '16px 32px',
    },
  },

  //ドロワー
  MuiDrawer: {
    paperAnchorBottom: {
      maxHeight: '90%',
    },
  },

  //カードのパーツ
  MuiCard: {
    root: {
      boxShadow: '0 0 0 1px #D6D6D6 inset',
    },
  },
  MuiCardHeader: {
    root: {
      backgroundColor: '#F2F5FA',
      border: 'solid 1px #D6D6D6',
      borderRadius: '16px 16px 0px 0px',
    },
    title: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },

  //ステッパー
  MuiStepper: {
    root: {
      paddingTop: 0,
      marginBottom: '16px',
    },
  },
  MuiStepLabel: {
    label: {
      marginTop: '8px !important',
    },
  },

  //イメージリスト
  MuiGridListTileBar: {
    root: {
      background: 'none',
    },
    actionIcon: {
      '& > button': {
        color: '#8b8b8b',
      },
    },
  },

  //ボタングループのパーツ
  MuiButtonGroup: {
    contained: {
      boxShadow: '0 0 0 1px #E5E5E5 inset',
    },
    groupedContainedHorizontal: {
      borderRight: ' 1px solid #E5E5E5',
    },
  },
})

//PC、タブレットの時にだけ適応されるOverrides
const pcOverrides: Overrides = Object.freeze({
  //ツールチップ
  MuiTooltip: {
    tooltip: {
      backgroundColor: '#FBFCFE',
      color: '#030303',
      borderRadius: 8,
      border: '1px solid #e0e0e0',
      maxWidth: 'inherit',
      padding: 0,
      width: 640,
    },
  },
})

//SPの時にだけ適応されるOverrides
// @ts-expect-error MUIの型が合わないようなので様子見
const spOverrides: Overrides = Object.freeze({
  //タイトル
  MuiTypography: {
    //ステッパーのタイトル
    body2: {
      fontSize: 10,
    },
  },

  //ツールチップ
  MuiTooltip: {
    tooltip: {
      backgroundColor: '#FBFCFE',
      color: '#030303',
      borderRadius: 8,
      border: '1px solid #e0e0e0',
      maxWidth: 'inherit',
      padding: 0,
      width: 290,
    },
  },

  //ダイアログ
  MuiDialogContent: {
    root: {
      padding: '8px 24px',
    },
    dividers: {
      padding: '16px 24px',
    },
  },

  //テーブル
  MuiTable: {
    root: {
      whiteSpace: 'nowrap', // テーブルは自動的に改行させない。これによってテーブルの横スクロールを機能させる
    },
  },

  //ステッパー
  MuiStepper: {
    root: {
      padding: 0,
      marginBottom: '32px',
    },
  },
})

const getOverrides = (isSp: boolean): Overrides => {
  const overrides = isSp ? spOverrides : pcOverrides

  return {
    ...globalOverrides,
    ...overrides,
  }
}

export type GlobalThemeProps = {
  children: React.ReactNode
}

export default function GlobalTheme({ children }: GlobalThemeProps) {
  const isSp = useSpMedia()

  const theme = Mui.createTheme({
    ...GLOBAL_THEME_OPTIONS,
    overrides: getOverrides(isSp),
  })
  return (
    <>
      <Mui.ThemeProvider theme={theme}>
        <Mui.CssBaseline />
        {children}
      </Mui.ThemeProvider>
    </>
  )
}
